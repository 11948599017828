@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Dangrek&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, 
body,
#root,
.app{
    height: 100%;
    background-color: black;
    @apply text-white;
}
#black-box{
  bottom: 5.2rem;
  top: -4.8rem;
  right: 4.8rem;
  left: -4.8rem;
  width: 98.5%;
}
#cursor::after {
    display: block;
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background-color: #7c95af;
    animation: cursor 0.6s linear infinite alternate;
    will-change: opacity;
 
  }
  
  @keyframes cursor {
    0%,
    40% {
      opacity: 1;
    }
  
    60%,
    100% {
      opacity: 0;
    }
  }